import fashion from "../Images/icons/fashion/New Project.png";
import fashion1 from "../Images/icons/fashion/New Project (1).png";
import fashion2 from "../Images/icons/fashion/New Project (2).png";
import fashion3 from "../Images/icons/fashion/New Project (3).png";
import fashion4 from "../Images/icons/fashion/New Project (4).png";
import fashion5 from "../Images/icons/fashion/New Project (5).png";
import fashion6 from "../Images/icons/fashion/New Project (6).png";
import fashion7 from "../Images/icons/fashion/New Project (7).png";
import fashion8 from "../Images/icons/fashion/New Project (8).png";
import fashion9 from "../Images/icons/fashion/New Project (9).png";
import fashion10 from "../Images/icons/fashion/New Project (10).png";
import fashion11 from "../Images/icons/fashion/New Project (11).png";
import fashion12 from "../Images/icons/fashion/New Project (12).png";
import fashion13 from "../Images/icons/fashion/New Project (13).png";
import fashion14 from "../Images/icons/fashion/New Project (14).png";
import fashion15 from "../Images/icons/fashion/New Project (15).png";
import fashion16 from "../Images/icons/fashion/New Project (16).png";
import fashion17 from "../Images/icons/fashion/New Project (17).png";
import fashion18 from "../Images/icons/fashion/New Project (18).png";

import interior from "../Images/icons/interior/New Project (2).png";
import interior1 from "../Images/icons/interior/New Project (2) (1).png";
import interior2 from "../Images/icons/interior/New Project (2) (2).png";
import interior3 from "../Images/icons/interior/New Project (2) (3).png";
import interior4 from "../Images/icons/interior/New Project (2) (4).png";
import interior5 from "../Images/icons/interior/New Project (2) (5).png";
import interior6 from "../Images/icons/interior/New Project (2) (6).png";
import interior7 from "../Images/icons/interior/New Project (2) (7).png";
import interior8 from "../Images/icons/interior/New Project (2) (8).png";
import interior9 from "../Images/icons/interior/New Project (2) (9).png";
import interior10 from "../Images/icons/interior/New Project (2) (10).png";
import interior11 from "../Images/icons/interior/New Project (2) (11).png";
import interior12 from "../Images/icons/interior/New Project (2) (12).png";
import interior13 from "../Images/icons/interior/New Project (2) (13).png";
import interior14 from "../Images/icons/interior/New Project (2) (14).png";
import interior15 from "../Images/icons/interior/New Project (2) (15).png";
import interior16 from "../Images/icons/interior/New Project (2) (16).png";
import interior17 from "../Images/icons/interior/New Project (2) (17).png";
import interior18 from "../Images/icons/interior/New Project (2) (18).png";
import interior19 from "../Images/icons/interior/New Project (2) (19).png";

import fashionBanner from "../Images/FASHION-BANNER.jpg";
import interiorBanner from "../Images/interior-design.jpg";

const CourseData = [
  {
    img: fashionBanner,
    id: "01",
    title: "B.SC in Fashion Design",
    slug: "/bsc-fashion-design",
    description:
      "The B.Sc in Fashion Design program aims to provide students with a comprehensive understanding of fashion design principles, techniques, and industry practices. Following the guidelines of the National Education Policy (NEP) 2020, this course combines theoretical knowledge with practical skills to equip students for successful careers in the dynamic field of fashion design in India. Throughout the course, emphasis will be placed on practical learning through industry collaborations, workshops, guest lectures, and internships to provide students with real-world exposure and experience.",
    level: "B.Sc",
    Duration: "36 months",
    Eligibility: "12th Grade",
    icons: [
      fashion,
      fashion1,
      fashion2,
      fashion3,
      fashion4,
      fashion5,
      fashion6,
      fashion7,
      fashion8,
      fashion9,
      fashion10,
      fashion11,
      fashion12,
      fashion13,
      fashion14,
      fashion15,
      fashion16,
      fashion17,
      fashion18,
    ],
    cources: [
      {
        title: "First Year",
        syllabus: [
          {
            title: "SEMESTER 1",
            topics: [
              "Introduction to fashion designing (Th+Pr)",
              "Component of Fashion Designing (Th+Pr)",
              "Fashion Illustration - I (Pr)",
              "Digital Marketing (Th+Pr)",
              "Surface Ornamentation (Pr)",
              "Pattern Drafting (Pr)",
              "Communication skill - I (Th)",
              "Computer Fundamentals (Pr)",
              "Indian history of fashion (Th)",
              "Co-Curricular activity",
            ],
          },
          {
            title: "SEMESTER 2",
            topics: [
              "Textile science (Th+Pr)",
              "Textile designing (Th+Pr)",
              "Fashion Illustration - II (Th+Pr)",
              "Sustainability in Fashion industry (Pr)",
              "Open elective*",
              "Garment construction - I (Pr)",
              "Introduction to fashion merchandising (Th)",
              "Communication skill - II (Th)",
              "Advanced Photoshop (Pr)",
              "Co-Curricular activity",
            ],
          },
        ],
      },
      {
        title: "Second Year",
        syllabus: [
          {
            title: "SEMESTER 3",
            topics: [
              "Advanced Fashion Illustration - I (Th+Pr)",
              "Merchandising (Th+Pr)",
              "Garment Construction - II (Pr)",
              "Fashion, Styling and accessories (Th+Pr)",
              "Open elective subject*",
              "Introduction to CorelDRAW (Pr)",
              "Modern Indian Languages - I (Th)",
              "Co-curricular activities",
              "Field Project",
            ],
          },
          {
            title: "SEMESTER 4",
            topics: [
              "Advanced Fashion Illustration - II (Th+Pr)",
              "Garment Construction - III (Pr)",
              "Advanced CorelDRAW (Pr)",
              "Fashion Forecasting & Draping mannequins (Th+Pr)",
              "Open elective subject*",
              "Yarn Craft - I (Pr)",
              "Modern Indian Languages - II (Th)",
              "Co-curricular activities",
              "Community engagement program",
            ],
          },
        ],
      },
      {
        title: "Third Year",
        syllabus: [
          {
            title: "SEMESTER 5",
            topics: [
              "Fashion Journalism",
              "Advanced styling",
              "Brand merchandising",
              "Major elective subject",
              "List for SEM V (Example):",
              "<ol><li>Construction of Ethenic Wear </li><li>Construction of Western Wear</li></ol>",
              "Accessories designing",
              "Advanced Garment Construction",
            ],
          },
          {
            title: "SEMESTER 6",
            topics: [
              "Men's wear construction",
              "Creative textile and craft heritage",
              "Advanced Fashion Portfolio",
              "Fashion Business Management",
              "OJT",
            ],
          },
        ],
      },
    ],
  },
  {
    id: "02",
    img: interiorBanner,
    title: "B.SC in Interior Design",
    slug: "/bsc-interior-design",
    description:
      "This one is the premier degree program of Interior Designing. During the program, you will develop an in-depth understanding of the tools and techniques which are used in Interior Designing. You will be interacting with our Subject Matter Experts, who have worked on projects that have been acclaimed both nationally and internationally.",
    level: "B.Sc",
    Duration: "36 months",
    Eligibility: "12th Grade",
    icons: [
      interior,
      interior1,
      interior2,
      interior3,
      interior4,
      interior5,
      interior6,
      interior7,
      interior8,
      interior9,
      interior10,
      interior11,
      interior12,
      interior13,
      interior14,
      interior15,
      interior16,
      interior17,
      interior18,
      interior19,
    ],
    cources: [
      {
        title: "First Year",
        syllabus: [
          {
            title: "SEMESTER 1",
            topics: [
              "Building & Interior Components (Th+Pr)",
              "Design Principles (Th)",
              "Furniture Design Drawings (Pr)",
              "Fundamentals of Digital Marketing (Th)",
              "Soft Skills Development (Th)",
              "Basics of Drafting (Pr)",
              "Furniture Materials (Th+Pr)",
              "Environmental Studies - I (Th)",
              "Computer Fundamentals - AutoCAD Basics (Pr)",
              "History of Indian Interiors (Th)",
              "Co-Curricular Activities",
            ],
          },
          {
            title: "SEMESTER 2",
            topics: [
              "Studio Interior Furnishing - I (Pr)",
              "Kitchen Design (Th+Pr)",
              "Design and Drafting (Pr)",
              "Interior Components & Treatment (Th+Pr)",
              "Fundamentals of Digital Marketing - I (Th)",
              "Business Communication (Th)",
              "History of Interiors in the West (Th)",
              "Basics of Furniture Construction (Pr)",
              "Environmental Studies - II (Th)",
              "Computer Fundamentals - AutoCAD II (Th)",
              "Co-Curricular Activities ",
            ],
          },
        ],
      },
      {
        title: "Second Year",
        syllabus: [
          {
            title: "SEMESTER 3",
            topics: [
              "Studio Interior Furnishing - II (Pr)",
              "Interior Components & Treatment - II (Th+Pr)",
              "Interior Services - I (Th+Pr) ",
              "Open Elective* (Th+Pr)",
              "Interior Materials and Prducts (Th)",
              "Modern Indian Language",
              "Field Project (Industry Visit + Report)",
              "Co-curricular course related to Interiors",
            ],
          },
          {
            title: "SEMESTER 4",
            topics: [
              "Studio Interior Furnishing - III (Pr)",
              "Interior Components & Treatment - III (Th+Pr)",
              "Interior Services - II (Th+Pr) ",
              "Landscaping & Gardening (Th+Pr)",
              "Interior Working Drawing - I (Pr)",
              "Modern Indian Language",
              "Community Engagement Service in collaboration with an NGO",
              "Co-curricular course related to Interiors",
            ],
          },
        ],
      },
      {
        title: "Third Year",
        syllabus: [
          {
            title: "SEMESTER 5",
            topics: [
              "Interior Design Dissertation - I (Th)",
              "Interior Professional Practice - I (Th)",
              "Interior Working Drawing - II (Pr)",
              "Electives I (Th+Pr)",
              "Interior Services - III (Th+Pr)",
              "Research Methodology (Th)",
              "Community Engagement Service relevant to Interior Design",
            ],
          },
          {
            title: "SEMESTER 6",
            topics: [
              "Interior Design Dissertation - II (Th+Pr)",
              "Interior Professional Practice - II (Th)",
              "Interior Working Drawing - III (Pr)",
              "Electives II (Th+Pr)",
              "Interior Services - IV (Th+Pr)",
              "Internship (OJT)",
            ],
          },
        ],
      },
    ],
  },
];
export default CourseData;
