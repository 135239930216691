import React from "react";
import "../Css/Work.css";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { FaExternalLinkAlt } from "react-icons/fa";
import "swiper/css";
import "swiper/css/bundle";
// import 'swiper/css/grid';
import "swiper/css/navigation";

// import { Navigation } from "swiper";
import { Grid, Pagination } from "swiper";

const Work2 = ({ data }) => {
  return (
    <div>
      <Swiper
        style={{ paddingBottom: "60px" }}
        grid={{
          rows: 2,
          fill: "row",
        }}
        breakpoints={{
          570: {
            slidesPerView: 1,
            spaceBetween: 70,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 50,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 4,

            spaceBetween: 30,
          },
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Grid, Pagination]}
        className="mySwiper1"
      >
        {data.map((value) => {
          const {
            Slugs,
            CImage,
            CategoryName,
            Cap,
            Duration,
            Ctitle,
            Desc,
            Icon,
            Earn,
          } = value;

          return (
            <SwiperSlide
              style={{
                background: "white",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                borderRadius: "10px",
                overflow: "hidden",
              }}
            >
              <div>
                <div style={{ height: "150px" }}>
                  <img
                    src={CImage}
                    className=""
                    alt=""
                    style={{ height: "100%", width: "100%" }}
                  />
                </div>
                <div style={{ padding: "10px", height: "260px" }}>
                  <div className="">
                    <div style={{ color: "black", paddingBottom: "5px" }}>
                      {Desc}{" "}
                    </div>
                  </div>
                  <div className="">
                    <div style={{ color: "black", paddingBottom: "5px" }}>
                      <img
                        src={Icon}
                        style={{ width: 20, height: 20, marginRight: 5 }}
                        alt="Thumb"
                      />
                      {CategoryName}
                      <span>{Ctitle}</span>
                    </div>

                    <div
                      className=""
                      style={{ color: "blue", paddingBottom: "5px" }}
                    >
                      <img
                        src={Cap}
                        style={{ width: 15, height: 15, marginRight: 5 }}
                        alt="Thumb"
                      />
                      {Earn}
                    </div>
                    <div
                      style={{
                        fontSize: "13",
                        color: "black",
                        paddingBottom: "5px",
                      }}
                    >
                      {Duration}
                    </div>
                    <Link
                      to={`${Slugs}`}
                      style={{
                        textDecoration: "none",
                        color: "blue",
                        display: "inline-flex",
                        alignItems: "center",
                      }}
                    >
                      Learn more{" "}
                      <span
                        style={{
                          paddingLeft: "10px",
                          textDecoration: "underline",
                        }}
                      >
                        <FaExternalLinkAlt />
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default Work2;
