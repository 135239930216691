// ----------filed visit------------------------
import w3 from "../Images/field Visits -20240404T074649Z-001/field Visits/IMG-20240112-WA0007.jpg";
import w4 from "../Images/field Visits -20240404T074649Z-001/field Visits/IMG-20240112-WA0010.jpg";
import w5 from "../Images/field Visits -20240404T074649Z-001/field Visits/IMG-20240129-WA0026.jpg";
import w6 from "../Images/field Visits -20240404T074649Z-001/field Visits/WhatsApp Image 2024-01-30 at 3.25.35 PM.jpeg";
import w7 from "../Images/field Visits -20240404T074649Z-001/field Visits/WhatsApp Image 2024-01-27 at 8.18.35 PM (1).jpeg";
import w8 from "../Images/field Visits -20240404T074649Z-001/field Visits/WhatsApp Image 2024-02-16 at 11.40.14_1e8682eb.jpg";
// --------------------students work------------------------
import w1 from "../Images/Student Works -20240404T100227Z-001/Student Works/IMG-20240327-WA0012.jpg";
import w2 from "../Images/Student Works -20240404T100227Z-001/Student Works/IMG-20240327-WA0011.jpg";
import w9 from "../Images/Student Works -20240404T100227Z-001/Student Works/IMG-20240327-WA0006.jpg";
import w10 from "../Images/Student Works -20240404T100227Z-001/Student Works/IMG-20240327-WA0007.jpg";
import w11 from "../Images/Students work/WhatsApp Image 2023-12-06 at 2.20.44 PM.jpeg";
import w12 from "../Images/Student Works -20240404T100227Z-001/Student Works/IMG-20240327-WA0010.jpg";
import w13 from "../Images/Student Works -20240404T100227Z-001/Student Works/IMG-20240327-WA0008.jpg"

// --------------------------------Festivals----------------
import fest1 from "../Images/Events-20240404T074656Z-001/Events/IMG-20240328-WA0009.jpg";
import fest2 from "../Images/Events-20240404T074656Z-001/Events/IMG-20240402-WA0052.jpg";
import fest3 from "../Images/Events-20240404T074656Z-001/Events/IMG-20240402-WA0053.jpg";
import fest4 from "../Images/Events-20240404T074656Z-001/Events/IMG-20240402-WA0055.jpg";
import fest5 from "../Images/Events-20240404T074656Z-001/Events/WhatsApp Image 2024-01-04 at 3.32.45 PM (6).jpeg";
// import fest6 from "../Images/Campus Culture Pics -20240115T070222Z-001/WhatsApp Image 2024-01-04 at 3.32.45 PM (7).jpeg";
// ------------------------------meetings-------------------------------
import meet1 from "../Images/Workshops -20240404T074653Z-001/Workshops/IMG-20240212-WA0026.jpg"
import meet2 from "../Images/Workshops -20240404T074653Z-001/Workshops/IMG-20240219-WA0007.jpg"
import meet3 from "../Images/Workshops -20240404T074653Z-001/Workshops/IMG-20240402-WA0030.jpg";
import meet4 from "../Images/Workshops -20240404T074653Z-001/Workshops/WhatsApp Image 2023-09-30 at 5.30.55 PM (1).jpeg"
import meet5 from "../Images/Workshops -20240404T074653Z-001/Workshops/WhatsApp Image 2023-09-30 at 5.30.55 PM (2).jpeg";
const StudWork = [

    {
      id: 1,
      Category:"Industrial Visits",
      Eventname:"Campus Tour",
      Eventimg:w3,
        },
  
    
    
    {
      id:1,
      Category:"Industrial Visits",
      Eventname:"Industrial Visits",
      
      Eventimg:w4,
     
    

    },
   
    {
      id:1,
      Category:"Industrial Visits",
      Eventname:"Industrial Visits",
      
      Eventimg:w5,
     
    

    },
   
    {
      id:1,
      Category:"Industrial Visits",
      Eventname:"Industrial Visits",
      Eventimg:w6,
    },
   
    {
      id:1,
      Category:"Industrial Visits",
      Eventname:"Industrial Visits",
      Eventimg:w7,
    },
   
    {
      id:1,
      Category:"Industrial Visits",
      Eventname:"Industrial Visits",
      Eventimg:w8,
    },
   
      
{id:2,
      Category:"Students Work",
      Timage:w1,
      Tname:"Sajid Momin",
      Desig:"Senior Faculty, Fashion Designing ",
      Eventname:"Placements",
  Eventimg:w1,
 

    },
    
   
    {
      id: 2,
     
      Category:"Students Work",
      Eventimg:w2,
      Tname:"Gayatri Barshi",
      Desig:"Senior Faculty, Fashion Designing ",
     
    },
    

    {
      id: 2,
     
      Category:"Students Work",
      Eventimg:w9,
      Tname:"Gayatri Barshi",
      Desig:"Senior Faculty, Fashion Designing ",
     
    },
    {
      id: 2,
     
      Category:"Students Work",
      Eventimg:w10,
      Tname:"Gayatri Barshi",
      Desig:"Senior Faculty, Fashion Designing ",
     
    },
    {
      id: 2,
     
      Category:"Students Work",
      Eventimg:w11,
      Tname:"Gayatri Barshi",
      Desig:"Senior Faculty, Fashion Designing ",
     
    },
    {
      id: 2,
     
      Category:"Students Work",
      Eventimg:w13,
      Tname:"Gayatri Barshi",
      Desig:"Senior Faculty, Fashion Designing ",
     
    },
    {
      id: 2,
     
      Category:"Students Work",
      Eventimg:w12,
      Tname:"Gayatri Barshi",
      Desig:"Senior Faculty, Fashion Designing ",
     
    },
   
    {
      id: 3,
     
      Category:"Celebrations",
      Eventimg:fest1,
      Tname:"Gayatri Barshi",
      Desig:"Senior Faculty, Fashion Designing ",
     
    },
  
    {
      id: 3,
     
      Category:"Celebrations",
      Eventimg:fest5,
      Tname:"Gayatri Barshi",
      Desig:"Senior Faculty, Fashion Designing ",
     
    },
    {
      id: 3,
     
      Category:"Celebrations",
      Eventimg:fest2,
      Tname:"Gayatri Barshi",
      Desig:"Senior Faculty, Fashion Designing ",
     
    },
    {
      id: 3,
     
      Category:"Celebrations",
      Eventimg:fest3,
      Tname:"Gayatri Barshi",
      Desig:"Senior Faculty, Fashion Designing ",
     
    },
    {
      id: 3,
     
      Category:"Celebrations",
      Eventimg:fest4,
      Tname:"Gayatri Barshi",
      Desig:"Senior Faculty, Fashion Designing ",
     
    },
    
    {
      id: 4,
     
      Category:"Workshop & Seminar/Meets",
      Eventimg:meet1,
      Tname:"Gayatri Barshi",
      Desig:"Senior Faculty, Fashion Designing ",
     
    },
    {
      id: 4,
     
      Category:"Workshop & Seminar/Meets",
      Eventimg:meet2,
      Tname:"Gayatri Barshi",
      Desig:"Senior Faculty, Fashion Designing ",
     
    },
    {
      id: 4,
     
      Category:"Workshop & Seminar/Meets",
      Eventimg:meet3,
      Tname:"Gayatri Barshi",
      Desig:"Senior Faculty, Fashion Designing ",
     
    },

    {
    id:5,
    Category:"Workshop & Seminar/Meets",
      Eventimg:meet4,
  },
  {
    id:6,
    Category:"Workshop & Seminar/Meets",
      Eventimg:meet5,
  },
    
  ];
  export default StudWork;